import React from "react";
import Layout from "../components/layout";

const NotFound = () => (
  <Layout>
    <h1>The page you are looking for cannot be found</h1>
  </Layout>
);

export default NotFound;
